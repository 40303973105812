import { GetServerSidePropsContext } from "next";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { match, pathToRegexp } from "path-to-regexp";
import type { QueryParams } from "../../dataAccess/QueryParams";

export enum PageAccess {
  Public,
  Restricted,
  Private,
}

const PLP = dynamic(() => import("../../components/pages/PLP"), {
  suspense: true,
});

const PDP = dynamic(() => import("../../components/pages/PDP"), {
  suspense: true,
});

const QuoteSubmit = dynamic(
  () => import("../../components/pages/QuoteSubmit"),
  {
    suspense: true,
  },
);

const Login = dynamic(() => import("../../components/pages/LogIn"), {
  suspense: true,
});

const SignUp = dynamic(() => import("../../components/pages/SignUp"), {
  suspense: true,
});

const VerifyEmail = dynamic(
  () => import("../../components/pages/VerifyEmail"),
  {
    suspense: true,
  },
);

const ForgotPassword = dynamic(
  () => import("../../components/pages/ForgotPassword"),
  {
    suspense: true,
  },
);

const ResetPassword = dynamic(
  () => import("../../components/pages/ResetPassword"),
  {
    suspense: true,
  },
);

const CookiesHelper = dynamic(
  () => import("../../components/pages/CookiesHelper"),
  {
    suspense: true,
  },
);

export const pageMapping = {
  PLP: {
    component: PLP,
    path: "/",
    access: PageAccess.Restricted,
    namedExports: () =>
      import("../../components/pages/PLP").then(({ getServerSideProps }) => ({
        getServerSideProps,
      })),
    isOnboardingPage: false,
    ignoreRedirectAfterAuthentication: false,
  },
  PDP: {
    component: PDP,
    path: "/:id",
    access: PageAccess.Restricted,
    namedExports: () =>
      import("../../components/pages/PDP").then(({ getServerSideProps }) => ({
        getServerSideProps,
      })),
    isOnboardingPage: false,
    ignoreRedirectAfterAuthentication: false,
  },
  QuoteSubmit: {
    component: QuoteSubmit,
    path: "/:id/quote",
    access: PageAccess.Private,
    namedExports: () => import("../../components/pages/QuoteSubmit"),
    isOnboardingPage: false,
    ignoreRedirectAfterAuthentication: false,
  },
  Login: {
    component: Login,
    path: "/accounts/login",
    access: PageAccess.Public,
    namedExports: () => import("../../components/pages/LogIn"),
    isOnboardingPage: true,
    ignoreRedirectAfterAuthentication: false,
  },
  SignUp: {
    component: SignUp,
    path: "/accounts/sign-up",
    access: PageAccess.Public,
    namedExports: () => import("../../components/pages/SignUp"),
    isOnboardingPage: true,
    ignoreRedirectAfterAuthentication: false,
  },
  VerifyEmail: {
    component: VerifyEmail,
    path: "/accounts/verify-email",
    access: PageAccess.Public,
    namedExports: () => import("../../components/pages/VerifyEmail"),
    isOnboardingPage: true,
    ignoreRedirectAfterAuthentication: false,
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/accounts/forgot-password",
    access: PageAccess.Public,
    namedExports: () => import("../../components/pages/ForgotPassword"),
    isOnboardingPage: true,
    ignoreRedirectAfterAuthentication: false,
  },
  ResetPassword: {
    component: ResetPassword,
    path: "/accounts/reset-password",
    access: PageAccess.Public,
    namedExports: () => import("../../components/pages/ResetPassword"),
    isOnboardingPage: true,
    ignoreRedirectAfterAuthentication: true,
  },
  CookiesHelper: {
    component: CookiesHelper,
    path: "/cookies/help",
    access: PageAccess.Public,
    namedExports: () => import("../../components/pages/CookiesHelper"),
    isOnboardingPage: false,
    ignoreRedirectAfterAuthentication: false,
  },
};

export type PageMapping = typeof pageMapping;

export function getRouteByPath<T extends Partial<QueryParams>>(
  pathToFind: string,
) {
  const route = (Object.keys(pageMapping) as (keyof PageMapping)[]).find(
    (pageType) => {
      const page = pageMapping[pageType];
      if ("path" in page) {
        return pathToRegexp(page.path).test(pathToFind);
      }
    },
  );

  const currentPage = route && pageMapping[route];
  if (currentPage && "path" in currentPage) {
    const { params } = match(currentPage?.path || "")(pathToFind) as {
      params: Partial<QueryParams> | T;
    };

    return (
      currentPage && {
        id: route,
        currentPage,
        path: pathToFind,
        params,
      }
    );
  }
}

export function getRouteFromServerSideContext(
  context: GetServerSidePropsContext,
) {
  const {
    query: { page },
  } = context;
  const path = `/${(typeof page === "string"
    ? [page]
    : page?.filter((pageParam) => pageParam !== "undefined") || []
  ).join("/")}`;
  return getRouteByPath(path);
}

export function useCurrentRoute<T extends Partial<QueryParams>>() {
  const router = useRouter();
  return getRouteByPath<T>(router.asPath.replace(/\?.+/, ""));
}
