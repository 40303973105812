import { jsonParseSafe, jsonStringifySafe } from "../helpers/formatter";
import type { SettingsConfiguration } from "../builder/Theme";
import type { AvailableComponents as AvailableComponentsPLP } from "../components/pages/PLP";
import type { AvailableComponents as AvailableComponentsPDP } from "../components/pages/PDP";
import type { AvailableComponents as AvailableComponentsQuoteDetails } from "../components/pages/QuoteSubmit";
import { setByPath } from "../helpers/object";
import type { PageMapping } from "../builder/mapping/page";
import type { ThemeSettings } from "../types/theme";
import { EditorHistory } from "./EditorHistory";

export type ComponentList = AvailableComponentsPLP &
  AvailableComponentsPDP &
  AvailableComponentsQuoteDetails;

export type ComponentType = keyof ComponentList;

interface ThemeObject<T = ComponentList> {
  uniqueId: string;
  settings: SettingsConfiguration;
  components?: ThemeComponent<T>[];
}

export interface ThemeComponent<T = ComponentList> extends ThemeObject<T> {
  type: ComponentType;
}

export interface Page extends ThemeObject {
  id: keyof PageMapping;
}

export interface ThemeConfiguration {
  pages: Page[];
  settings: ThemeSettings;
  uniqueId: "theme";
}

export type ThemeObjectType = ThemeConfiguration | Page | ThemeComponent;

export interface ThemeObjectResult {
  match: ThemeObjectType;
  parent?: ThemeObjectType;
}

function findInJsonTheme(
  uniqueIdToFind: string,
  jsonTheme?: ThemeObjectType,
  parent?: ThemeObjectType,
): ThemeObjectResult | null {
  if (typeof jsonTheme !== "object" || jsonTheme === null) {
    return null;
  }

  if (uniqueIdToFind === "theme" || jsonTheme.uniqueId === uniqueIdToFind) {
    return { match: jsonTheme, parent };
  }

  let result: ThemeObjectResult | null = null;

  let components: (Page | ThemeComponent)[] = [];
  if ("pages" in jsonTheme) {
    components = jsonTheme.pages;
  } else if ("components" in jsonTheme) {
    components = jsonTheme.components || [];
  }

  components.forEach((child) => {
    const found = findInJsonTheme(uniqueIdToFind, child, jsonTheme || parent);

    if (found && found.match) {
      result = found;
      return false;
    }
  });

  return result;
}

export interface EditorObject extends Omit<ThemeObject, "components"> {
  type: string;
  parent?: EditorObject;
  originalObject: ThemeObjectType;
  components?: EditorObject[];
}

interface Options {
  ignoreHistory?: boolean;
  silent?: boolean;
}

export type JsonSettingValue =
  SettingsConfiguration[keyof SettingsConfiguration];

export class JsonThemeObject {
  private theme: ThemeConfiguration;

  private observers: (() => void)[] = [];

  public constructor(theme: ThemeConfiguration) {
    this.theme = theme;
    EditorHistory.init(this);
  }

  public addObserver(observer: () => void) {
    this.observers.push(observer);
  }

  public notifyObservers() {
    this.observers.forEach((observer) => observer());
  }

  public saveSetting(
    uniqueId: string,
    setting: string,
    value: JsonSettingValue,
    options?: Options,
  ) {
    const themeObject = findInJsonTheme(uniqueId, this.theme);
    setByPath(themeObject?.match, setting, value);
    if (!options?.ignoreHistory) {
      EditorHistory.insertSaveSetting(uniqueId, setting, value);
    }
    if (!options?.silent) {
      this.notifyObservers();
    }
  }

  public removeComponent(uniqueIdToRemove: string, options?: Options) {
    const themeObject = findInJsonTheme(uniqueIdToRemove, this.theme);
    if (themeObject?.parent && "components" in themeObject?.parent) {
      setByPath(
        themeObject?.parent,
        "components",
        themeObject?.parent.components?.filter(
          ({ uniqueId }) => uniqueIdToRemove !== uniqueId,
        ),
      );
      if (!options?.ignoreHistory) {
        EditorHistory.insertRemoveComponent(uniqueIdToRemove);
      }
      if (!options?.silent) {
        this.notifyObservers();
      }
    }
  }

  public addComponent(
    parentUniqueId: string,
    component: ThemeComponent,
    options?: Options,
  ) {
    const themeObject = findInJsonTheme(parentUniqueId, this.theme);
    if (themeObject && "components" in themeObject.match) {
      themeObject.match.components?.unshift(component);
      if (!options?.ignoreHistory) {
        EditorHistory.insertAddComponent(parentUniqueId, component);
      }
      if (!options?.silent) {
        this.notifyObservers();
      }
    }
  }

  public getPageOrComponent(uniqueId: string): EditorObject | null {
    const { match, parent } = findInJsonTheme(uniqueId, this.theme) || {};

    function convertToEditorObject(
      themeObject?: ThemeObjectType,
      parent?: ThemeObjectType,
    ): EditorObject | null {
      const pageId = themeObject && "id" in themeObject && themeObject.id;
      const componentType =
        themeObject && "type" in themeObject && themeObject.type;
      const components =
        themeObject && "components" in themeObject && themeObject.components;
      const pages = themeObject && "pages" in themeObject && themeObject.pages;
      return themeObject?.uniqueId
        ? {
            type: pageId || componentType || "Theme",
            parent: convertToEditorObject(parent) || undefined,
            originalObject: themeObject,
            components: (components || pages || [])
              .map((obj) => convertToEditorObject(obj, parent))
              .filter((exists) => exists) as EditorObject[],
            settings: themeObject?.settings || {},
            uniqueId: themeObject?.uniqueId,
          }
        : null;
    }

    return convertToEditorObject(match, parent || undefined);
  }

  public getTheme(): ThemeConfiguration {
    return JSON.parse(JSON.stringify(this.theme));
  }

  public setTheme(
    theme: ThemeConfiguration,
    options?: Pick<Options, "silent">,
  ) {
    const json = jsonParseSafe<ThemeConfiguration>(
      jsonStringifySafe(theme) || "",
    );
    if (json) {
      this.theme = json;
    }
    if (!options?.silent) {
      this.notifyObservers();
    }
  }
}
